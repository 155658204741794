<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title"> Invoice Correction </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  label="Select Fee"
                  :items="fees"
                  class="pa-0"
                  v-model="feeId"
                  @change="invoice()"
                  outlined
                  dense
                />
                {{ invoices.length }}
              </v-flex>
            </v-card-title>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :server-items-length="invoices.length"
              :items="invoices"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td>{{ ++index }}</td>
                  <td>{{ item.enroll_code }}</td>
                  <td>{{ item.fee_head }}</td>
                  <td>{{ item.fee_head_amount }}</td>
                  <td>{{ item.paid_amount }}</td>
                  <td>{{ item.sod_date }}</td>
                  <td>{{ item.transaction_date }}</td>
                  <td align="right">
                    <v-btn
                      outlined
                      small
                      @click="
                        (selectedInvoiceList = item),
                          (updateInvoiceDialogue = true),
                          getMoveToInvoiceList()
                      "
                      >Correct</v-btn
                    >
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="updateInvoiceDialogue" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Add/Update</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-select
            :items="moveInvoiceList"
            required
            class="pa-0"
            label="Base Level*"
            outlined
            dense
            v-model="toInvoice"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="updateInvoiceDialogue = false, selectedInvoiceList={}, moveInvoiceList=[], toInvoice=''"
            >Close</v-btn
          >
          <v-btn color="success" @click="moveInvoice" text>Move</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],
  data: () => ({
    updateInvoiceDialogue: false,
    fees: [],
    feeId: "",
    toInvoice: "",
    moveInvoiceList:[],
    selectedInvoiceList: {},
    invoices: [],
    headers: [
      { text: "#", align: "left", value: "id", width: 5, sortable: false },
      { text: "Enroll Code", align: "left", value: "fee_head", width: 50 },
      { text: "Fee Head", align: "left", value: "fee_head", width: 50 },
      { text: "Amount", align: "left", value: "fee_head_amount", width: 50 },
      { text: "Paid Amount", align: "left", value: "paid_amount", width: 50 },
      { text: "Invoice Date", align: "left", value: "invoice_date", width: 50 },
      {
        text: "Transaction Date",
        align: "left",
        value: "transaction_date",
        width: 50,
      },
      {
        text: "Action",
        align: "right",
        value: "transaction_date",
        width: 50,
      },
    ],
  }),
  computed: {
    
  },
  mounted() {
    this.getFeeHeads();
  },
  methods: {
    getFeeHeads() {
      this.$rest.get("/api/fee-head?itemsPerPage=50").then(({ data }) => {
        this.fees = data.data.map((res) => {
          return {
            value: res.id,
            text: res.title,
          };
        });
      });
    },
    invoice() {
      this.$rest
        .get("/api/report/billing-invoice?" + `feeId=${this.feeId}`)
        .then((data) => {
          this.invoices = data.data;
        });
    },
    getMoveToInvoiceList() {
      this.$rest
        .get(`/api/report/billing-invoice?type=get-invoice-list&enroll=${this.selectedInvoiceList.enroll_code}&paid_date=${this.selectedInvoiceList.transaction_date}`)
        .then((data) => {
          this.moveInvoiceList = data.data.map((item)=>{
            return {text:item.sod_date, value:item.invoice_no}
          });
        });
    },
    moveInvoice() {
    const selectedInvoice = this.moveInvoiceList.find(invoice =>invoice.value===this.toInvoice);
      this.$rest
        .get(`/api/report/billing-invoice?type=move-action&enroll=${this.selectedInvoiceList.enroll_code}&paid_date=${this.selectedInvoiceList.transaction_date}&amount=${this.selectedInvoiceList.paid_amount}&sod_date=${this.selectedInvoiceList.sod_date}&feeId=${this.feeId}&move_to_sod=${selectedInvoice.text}&move_from_invoice=${this.selectedInvoiceList.invoice_no}&invoice_detail_id=${this.selectedInvoiceList.id}&move_to_invoice=${selectedInvoice.value}`)
        .then((data) => {
            this.invoice();
            this.updateInvoiceDialogue=false;
            this.toInvoice = null;
        });
    }
  },
};
</script>
